<template>
  <section>
    <div class="mt-3 md:mt-0">
      <t-dropdown variant="smActions">
        <template
          v-slot:trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
        >
          <button
            class="report-status-dropdown-button focus:outline-none"
            aria-label="Report Status Menu"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <div v-if="details.status !== 'Verified'" class="flex items-center">
              <i class="text-3xl text-oRed fas fa-times-circle" />

              <span class="ml-2 font-medium text-gray-700">
                {{ details.status }}
              </span>
            </div>
            <div v-if="details.status === 'Verified'" class="flex items-center">
              <i class="text-3xl  text-oGreen fas fa-check-circle" />

              <span class="ml-4 font-medium text-gray-700">
                {{ details.status }}
              </span>
            </div>

            <template v-if="$acl.canUpdate('reports')">
              <i
                v-if="details.status !== 'Verified'"
                class="fas fa-chevron-down"
              />
            </template>
          </button>
        </template>

        <template
          v-slot:default="{ hide }"
          v-if="details.status !== 'Verified'"
        >
          <div
            v-if="$acl.canUpdate('reports')"
            @click="hide()"
            class="py-1 mt-px bg-white rounded-md shadow-md"
          >
            <t-dropdown-item class="font-medium" @click="popup">
              {{ $t('components.reportManagement.action.verify') }}
            </t-dropdown-item>
          </div>
        </template>
      </t-dropdown>
    </div>

    <VerificationPopupA
      :id="`reportVerificationPopupA-${primaryKey}`"
      :details="details"
      @change="$emit('change', $event)"
    />
    <VerificationPopupB
      :id="`reportVerificationPopupB-${primaryKey}`"
      :details="details"
      @change="$emit('change', $event)"
    />
  </section>
</template>

<script>
import { EventBus } from '@/utils/EventBus'

export default {
  name: 'StatusDropdownBlock',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
    VerificationPopupA: () => import('./VerificationPopupA.vue'),
    VerificationPopupB: () => import('./VerificationPopupB.vue'),
  },
  props: {
    primaryKey: {
      type: String,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  methods: {
    popup() {
      // currently reverting the verification is prohibited
      // it might be enabled in the future
      if (this.details.status === 'Verified') {
        this.$notify({
          group: 'generic',
          type: 'danger',
          title: 'Revert Report Verification',
          text: 'You are not allowed to revert a verified report.',
        })
        return
      }

      const aWhitelist = ['Damaged Vehicle', 'Wrongly Parked']

      const popup = aWhitelist.includes(this.details.report_category)
        ? 'reportVerificationPopupA'
        : 'reportVerificationPopupB'

      EventBus.$emit('reportVerificationPopup')
      this.$modal.show(`${popup}-${this.primaryKey}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-status-dropdown-button {
  width: 145px;
  height: 42px;
  border-radius: 27px;
  box-shadow: 0 3px 6px 0 rgba(61, 73, 82, 0.22);
  background-color: #ffffff;
  @apply flex items-center justify-between pl-2 pr-3 text-14px text-oCharcoal;
}
</style>
