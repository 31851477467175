var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"mt-3 md:mt-0"},[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"report-status-dropdown-button focus:outline-none",attrs:{"aria-label":"Report Status Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.details.status !== 'Verified')?_c('div',{staticClass:"flex items-center"},[_c('i',{staticClass:"text-3xl text-oRed fas fa-times-circle"}),_c('span',{staticClass:"ml-2 font-medium text-gray-700"},[_vm._v(" "+_vm._s(_vm.details.status)+" ")])]):_vm._e(),(_vm.details.status === 'Verified')?_c('div',{staticClass:"flex items-center"},[_c('i',{staticClass:"text-3xl  text-oGreen fas fa-check-circle"}),_c('span',{staticClass:"ml-4 font-medium text-gray-700"},[_vm._v(" "+_vm._s(_vm.details.status)+" ")])]):_vm._e(),(_vm.$acl.canUpdate('reports'))?[(_vm.details.status !== 'Verified')?_c('i',{staticClass:"fas fa-chevron-down"}):_vm._e()]:_vm._e()],2)]}},(_vm.details.status !== 'Verified')?{key:"default",fn:function(ref){
        var hide = ref.hide;
return [(_vm.$acl.canUpdate('reports'))?_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{staticClass:"font-medium",on:{"click":_vm.popup}},[_vm._v(" "+_vm._s(_vm.$t('components.reportManagement.action.verify'))+" ")])],1):_vm._e()]}}:null],null,true)})],1),_c('VerificationPopupA',{attrs:{"id":("reportVerificationPopupA-" + _vm.primaryKey),"details":_vm.details},on:{"change":function($event){return _vm.$emit('change', $event)}}}),_c('VerificationPopupB',{attrs:{"id":("reportVerificationPopupB-" + _vm.primaryKey),"details":_vm.details},on:{"change":function($event){return _vm.$emit('change', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }